.switch-btn-group .btn:not(.active) {
  color: var(--secondary);
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.3rem;
}

.switch-btn-group .btn:not(.active):hover {
  color: var(--secondary);
  background-color: transparent;
  border-color: transparent;
}

.switch-btn-group .btn:not(.active):focus {
  box-shadow: none !important;
}

.switch-btn-group button.active {
  border-radius: 0.3rem !important;
}

/* Hack to fix issue with a modal opening a modal leaving overflow: hidden on the body */
body:not(.modal-open) {
  overflow: scroll !important;
}
.modal-open {
  overflow: hidden !important;
}
