html {
  scroll-behavior: smooth;
}

.client-table {
  table-layout: fixed;
}

.client-table tr td:nth-child(1) {
  width: 70px;
}

.client-table tr td:nth-child(2) {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#clientsTable tr.selected,
#clientsTable tr.selected:hover {
  color: var(--bs-primary);
  background-color: var(--secondary) !important;
}

.table-striped > tbody > tr.selected:nth-of-type(2n + 1) > *,
.table-striped-columns > :not(caption) > tr.selected > :nth-child(2n) {
  color: var(--bs-primary);
}
