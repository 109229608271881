// Fonts
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

// Theme colors
$blue:    #4987ce;
$red:     #f44455;
$yellow:  #fcc100;
$green:   #3BB897;
$cyan:    #47BAC1;
$pink:    #a180da;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary:          $blue;
$secondary:        $pink;
$tertiary:         lighten($blue, 10%);
$success:          $green;
$info:             $cyan;
$warning:          $yellow;
$danger:           $red;
$light:            $gray-100;
$dark:             #354052;

// Body
$body-bg: #F8FAFC;
$body-color: $gray-700;

// Border radius
$border-radius-sm: .15rem;
$border-radius:    .2rem;
$border-radius-lg: .2rem;

// Navbar
$navbar-border-bottom: 1px solid #EDEEF0;

// Card
$card-border-color: #EAECEF;
$card-shadow: 0 .5rem 1rem rgba(20,40,60,.03);
$card-title-font-weight: $font-weight-bold;

// Sidebar general
$sidebar-bg: $white;
$sidebar-border: 1px solid #EDEEF0;

// Sidebar brand
$sidebar-brand-color: $gray-800;
$sidebar-brand-font-weight: $font-weight-bold;

// Sidebar header
$sidebar-header-color: $gray-600;
$sidebar-header-text-transform: uppercase;
$sidebar-header-font-size: 0.65rem;

// Sidebar link
$sidebar-link-font-weight: $font-weight-bold;
$sidebar-link-color: $gray-600;
$sidebar-link-hover-color: $black;
$sidebar-link-hover-bg: $white;
$sidebar-link-hover-font-weight: $font-weight-bold;
$sidebar-link-active-color: $primary;
$sidebar-link-active-bg: $white;
$sidebar-link-active-font-weight: $font-weight-bold;

// Sidebar child link
$sidebar-link-child-color: $gray-600;
$sidebar-link-child-bg: $white;
$sidebar-link-child-hover-color: $black;
$sidebar-link-child-hover-bg: $white;
$sidebar-link-child-active-color: $primary;
$sidebar-link-child-active-bg: $white;

// Sidebar link icon
$sidebar-link-icon-color: $gray-600;
$sidebar-link-icon-hover-color: $black;
$sidebar-link-icon-active-color: $primary;

// Sidebar bottom
$sidebar-bottom-color: $gray-800;
$sidebar-bottom-bg: $gray-100;

// Simplebar
$simplebar-scrollbar-bg: rgba(darken($primary, 50%), 0.175);

// Hamburger
$hamburger-width-top: 24px;
$hamburger-width-middle: 24px;
$hamburger-width-bottom: 14px;
