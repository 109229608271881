.rbc-timeslot-group {
  min-height: 70px;
}

.rbc-today .rbc-time-slot,
.rbc-now .rbc-time-slot {
  border-top: 1px solid #e6e6e6;
}

/* .rbc-toolbar .rbc-btn-group {
	display: none;
} */

.calendar-day-view .rbc-toolbar .rbc-btn-group:first-child {
  display: inline-block;
}

#calendarCard .rbc-time-content {
  min-height: 350px;
  max-height: calc(100vh - 340px);
  overflow-y: auto;
}

@media screen and (max-width: 500px) {
  #calendarCard .rbc-time-content {
    max-height: calc(100vh - 370px);
  }
}

.grey-bg {
  background-color: #efefef;
  border-top: 1px solid #cdcdcd !important;
}

.confirmed-booking {
  background-color: #4caf50 !important;
  border: 1px solid #449c47 !important;
}

.pending-booking {
  background-color: #ff9800 !important;
  border: 1px solid #e68a00 !important;
}

.completed-booking {
  background-color: #9d7bd8 !important;
  border: 1px solid #8d66d1 !important;
}

.pendingcompletion-booking {
  background-color: #ff9800 !important;
  border: 1px solid #e68a00 !important;
}

.didnotattend-booking {
  background-color: #9d7bd8 !important;
  border: 1px solid #8d66d1 !important;
}

.historical-booking {
  background-color: #9d7bd8 !important;
  border: 1px solid #8d66d1 !important;
}

.block-booking {
  background-color: #333 !important;
  border: 1px solid #222 !important;
}

.pendingcompletion-booking *,
.completed-booking *,
.confirmed-booking *,
.pending-booking *,
.historical-booking *,
.block-booking *,
.didnotattend-booking * {
  color: white;
}

.rbc-toolbar-label {
  margin-top: 8px;
  margin-bottom: 5px;
}

.rbc-calendar.mobile .rbc-toolbar .rbc-btn-group:last-of-type {
  display: none;
}

.rbc-header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.rbc-agenda-view {
  flex: auto !important;
}

.rbc-agenda-view .rbc-header {
  border-bottom: none !important;
}

.rbc-agenda-view > .rbc-agenda-table {
  border-bottom: none !important;
}

.rbc-agenda-content > .rbc-agenda-table tr {
  border: 1px solid #ddd !important;
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell * {
  color: black !important;
  background-color: white !important;
}

.rbc-agenda-time-cell {
  color: black !important;
  background-color: white !important;
}

#calendarCard .calendar-month-view {
  min-height: 500px;
  height: calc(100vh - 230px);
}

.rbc-month-view {
  height: auto;
}
