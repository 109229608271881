.CalendarSync__providers {
  display: flex !important;
  border: none !important;
  margin-bottom: -8px;
}

.CalendarSync__status--active p {
  display: none !important;
  background-color: blue !important;
}

.CalendarSync__provider-auth {
  display: flex !important;
  justify-self: center;
  justify-items: center;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 14px !important;
  border-radius: 8px !important;
  min-height: 50px;
  min-width: 380px !important;
  text-align: center;
  height: 100%;
}

.CalendarSync__provider-icon {
  margin-left: auto !important;
}

.CalendarSync__title {
  display: none;
}

.CalendarSync__provider-auth-text {
  text-align: left !important;
  width: fit-content !important;
  max-width: fit-content !important;
  margin-right: auto;
}
