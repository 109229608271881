.navbar-nav .dropdown-menu {
  box-shadow: $box-shadow;
}

.dropdown .dropdown-menu.show {
  animation-name: dropdownAnimation;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    margin-top: -8px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

.dropdown .dropdown-menu-end {
  left: auto;
  right: 0;
}
