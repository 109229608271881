.form-control-no-border {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.form-select-lg {
	min-height: calc(2.7rem + 2px);
	border-radius: .2rem;
	padding-top: .55rem;
	padding-bottom: .55rem;
	padding-left: 1rem;
	font-size: 1rem;
}
