.marker-cluster {
	margin-left: -11px !important;
}

.marker-cluster span {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background-color: var(--blue);
	color: white;
	border: 1px solid black;
	border-radius: 50%;
}
